.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.image {
  background-size: cover;
  /* background-position: bottom; */
  background-repeat: no-repeat;
  /* background-color: rgb(0, 110, 255); */
  opacity: 1;
  height: "100%";
  /* width: 100%; */
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}













.bgImage {
  background-image: url("../src/assets/images/image1.png");
  background-size: cover;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 97vh;
  /* width: 100vw; */
  /* width: 100%; */
}

.center {
  /* text-align: center; */
  align-items: center;
  text-align: center;
  width: 100%;
  /* padding-top: 5%;
  padding-bottom: 5%; */
}

.padding {
  padding-top: 5%;
  padding-bottom: 5%;
}

.image {
  background-image: url("../src/assets/images/image3.png");
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  background-color: rgb(0, 110, 255);
  opacity: 1;
  /* width: 100%; */
}

@media (min-width: 300px) && (max-width:599 ){
  .flex-center{
    .min-height:86vh;
  }
}
@media (min-width: 600px) && (max-width:899 ){
  .flex-center{
    .min-height:92vh;
  }
}
@media (min-width: 1200px) && (max-width:1535 ){
  .flex-center{
    .min-height:94vh;
  }
}


@media (max-width: 1199px) {
  .image {
    display: none;
  }

}

.create-post-status .MuiOutlinedInput-notchedOutline, .create-post-status .Mui-focused .MuiOutlinedInput-notchedOutline {
  border: 0px solid transparent;
}

.create-post-status #outlined-multiline-static{
  height: unset !important;
  
}
.create-post-status .MuiOutlinedInput-root{
  padding: 0px;
}
.newsHeadingText{
  color: black;
  text-decoration: none;
}
.newsHeadingText:hover{
  color: rgb(60, 130, 245);
}