/*  */

:root{
    --background-page-btn-section: #E1EDFC;
    --background-page-btn-inactive: rgba(0, 0, 0, 0.12);
    --background-page-btn-active: #1976D2;
    --background-page-btn-active-hover: #1565C0;
    --background-page-btn-inactive-hover: #F5F5F5;
    --background-white: #FFFFFF;

    --border-add-btn: 1px dashed #2F80ED;

    --border-radius-icon-btn: 0.5rem;
    --border-radius-accordian: 1rem;
    --border-radius-add-btn: 2em;
    --border-radius-form: 1rem;
    --border-radius-button: 0.375rem;
    --border-radius-none: 0rem; /*previously --border-radius-videos-right-section*/
    --border-radius-leaderboard-wheel: 1.25rem;
    --border-radius-dashboard:0.5rem;
    --border-profile-image-hover:2px solid blue;
    --border-radius-dashboard-profile:2.5rem;
    --box-shadow-accordian-open: inset 5px 0 #2563EB;
    --box-shadow-none: none; /*previously --box-shadow-videos-right-section*/

    --column-gap: 1rem;
    --column-gap-half: 0.5rem;

    --font-size-2: 2rem;
    --font-size-3: 3rem;
    --font-size-dashboard-pending:0.75rem;

    --font-weight-2:200;
    --font-weight-3:300;
    --font-weight-4:400;
    --font-weight-5:500;
    --font-weight-6:600;
    --font-weight-7:700;
    --font-weight-leaderboard:450;

    --width-size: 1rem;
    --height-size: 1rem;
    --height-button: 2rem;
    --height-newsupdate-avatar:2.5rem;
    --margin-top-0_5: 0.5rem;
    --margin-top-1: 1rem;
    --margin-top-1_5: 1.5rem;
    --margin-top-2: 2rem;
    --margin-right-1: 1rem;
    --margin-left-0_5:0.5rem;
    --margin-left-leaderboard-badges:0rem !important;
    --margin-divider: 0.938rem;
    --margin-small: 0.1rem;
    --margin-none:0rem;
    --margin-leaderboard:0 0.25rem;
    --margin-linkedin-template:0.5rem 0.5rem 0.5rem 0rem;
    --margin-top-leaderboard-tab: 1.25rem;
    --max-width-leaderboard-badges:17.5rem;
    
    --min-width-full:100%;
    --min-width-icon-profile:2.5rem;
    --min-width-15: 15rem;
    --min-height-dashboard-components:25rem;
    --min-height-dashboard-components-video:1rem;
    --minWidth-welcome-dialog:30rem;

    --width-drawer:40rem;
    --width-drawer-sm:30rem;
    --width-profile-component:25rem;

    --padding-all-1: 1rem;
    --padding-all-1_5: 1.5rem;
    --padding-ldr:0rem 1rem 1rem 1rem;
    --padding-vertical-1: 1rem 0;
    --padding-horizontal-1: 0 1rem;
    --padding-top-1: 1rem;
    --padding-top-2: 2rem;
    --padding-bottom-1: 1rem;
    --padding-right-1: 1rem;
    --padding-left-1: 1rem;
    --padding-none:0rem;
    --padding-top-accordian-header: 0.5rem;
    --padding-videos-right:2rem;
    --padding-videos-infoicon:0 1rem;
    --padding-videos-bulbicon:0 0.59rem;
    --padding-dashboard-titles:1rem 0 0 0;
    --padding-tasklist-element5:5rem;
    --padding-tasklist-element6:6rem;
    --padding-tasklist-element7:7rem;
    --padding-tasklist-element8:8rem;
    --padding-tasklist-element9:9rem;
    --padding-dashboard-profile: 1rem 0.75rem;
    --padding-dashboard-dialog:0.5rem 1rem;
    --padding-congratulations-profile:1rem 1rem .5rem 1rem;
    --padding-left-only:0rem 0rem 0rem 1rem;
    --padding-right-only:0rem 1rem 0rem 0rem;

    --row-gap: 1rem;
    --row-gap1_5: 1rem;

    --background-color-theme: #EEF6FF;
    --background-color-chip: #E8EFFF;
    --background-color-none: none;
    --background-color-hover-videos:#A8CFFB !important;
    --bgcolor-policy-avatar:#2196F3;
    
    --width-button: 3rem;
    --width-autocomplete-to: 89%;
    --width-autocomplete-cc: 94%;
    --width-autocomplete-subject: 85%;
    --width-autocomplete-subject-sm: 82%;

    
    --width-full: 100%;
    --zero: 0;

    --equal-hw0_5:0.5rem;
    --equal-hw0_75:0.75rem;
    --equal-hw1:1rem;
    --equal-hw1_25:1.25rem;
    --equal-hw1_5:1.5rem;
    --equal-hw1_75:1.75rem;
    --equal-hw2:2rem;
    --equal-hw2_5:2.5rem;
    --equal-hw3:3rem;
    --equal-hw5:5rem;
    --equal-hw6:6rem;
    --equal-hw7:7rem;
    --equal-hw8:8rem;
    --equal-hw9:9.325rem;
    --none-imp:0rem !important;

    --height-drawer-button:2.625rem;
    --width-drawer-button:6.313rem;

    --color-leaderboard-title:#254EC1;
    --color-dashboard-tasklist:#64748b7d;
    --color-mui-primary-blue:#2563EB;
    --color-lavender:#CFAEDE75;
    --color-lightestblue:#F1F5FB;
    --color-lightblue:#DBEAFE;
    --color-lightgrey:#96A5B8;
    --color-blue:#2563EB;
    --color-white:#F8FBFF;
    --color-grey:#64748b;
    --color-greyblue:#475569;
    --color-black:#000000;


}