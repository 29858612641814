/* LoginMastech.css */

.login-background {
  width: 100%;
  height: 100%;
  background-position: center;
  background-image: url("../assets/images/image1.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.login-container {
  height: 94vh;
  align-items: center;
  justify-content: center;
  display: flex;
  align-self: center;
}


.card {
  width: 100%;
  align-items: center;
  display: flex;
  padding: 0;
  height: 100%;
}

.center {
  text-align: center;
}

.padding {
  padding: 16px;
}

.logo {
  height: 66px;
  width: 278px;
}

.title {
  padding-bottom: 4px;
  padding-top: 4px;
}

.form-control {
  margin: auto;
  width: 65%;
}

.link-container {
  width: 65%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  padding-bottom: 1px;
}

.checkbox-container {
  width: 65%;
  margin: auto;
  justify-content: start;
  padding-bottom: 1px;
  display: flex;
}

.button {
  width: 65%;
  background-color: rgba(247, 148, 51, 1);
}

.new-user {
  width: 65%;
  margin: auto;
  padding-bottom: 1px;
  margin-top: 3px;
}
