.btn-position {
    margin-top: 10px !important;
    padding: 6px 8px !important;
}

.icon-position {
    position: relative;
    top: 4px !important;
    margin: 2px !important;
}

.bgImage-style {
    min-height: 100vh;
    max-width: 100%;
    background-size: 100%;
    background-position: center top;
    background-repeat: no-repeat;

}

.video-thumb{

    height:5rem;
    width:5rem;
    border-radius:15px;
}

.overlay-whitelock-icon{
    position:absolute;
    color:white;
    height:35px;
    width:35px;

}


.text-align {
    display: flex;
    letter-spacing: 0.15px;
    word-wrap: break-word;
    justify-content: center;
    text-align: center;
    width: 62%;
    margin-left: 12rem;
    color: #ffff;
}
.text {
    color: #ffff;
    align-items: center;
    text-align: center;
    letter-spacing: 0.15px;
    margin-top: 3rem;
}
/* .first-paragraph {
    margin-bottom: 0 !important;
} */

.video-align {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    margin-left: 16rem;
    margin-top: 20px;
    border-radius: 12px;
    height: 21rem;
    border-radius: 16px;
    box-shadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)';
}

/* width */
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}
/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #bec4c4;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

/* table::-webkit-scrollbar {
    width: 10px;
} */

* {
    box-sizing: border-box;
}

.dialog-top-right-logo {
    position: absolute;
    top: 10px;
    right: 1.875rem;
}

.typography-heading-2x {
    font-size: 1.5rem;
}

/* 
.width-full {
    width: 100%;
} */

.items-center {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
#PolicyAndProcedureData .css-46bh2p-MuiCardContent-root {
    padding: 0 !important;
}

#PolicyAndProcedureData .css-h4y409-MuiList-root {
    padding-bottom: 0 !important;
}
.policy-data {
    overflow: auto;
    height: 19.5rem;
}

.policy-data::-webkit-scrollbar {
    display: none;
}

.news-update {
    overflow: auto;
    height: 22rem;
}

.news-update::-webkit-scrollbar {
    display: none;
}
.upcoming-events {
    overflow: auto;
    height: 22rem;
}

.upcoming-events::-webkit-scrollbar {
    display: none;
}

.table-height {
    overflow: auto;
    height: 22rem;
}
.table-height::-webkit-scrollbar {
    display: none;
}
#PolicyAndProcedureData .css-46bh2p-MuiCardContent-root:hover:not(.header) {
    background-color: #0000;
}

.align-content {
    display: grid;
    justify-content: center;
}

.btn-style {
    width: 10.25rem !important;
    height: 2.25rem;
    border-radius: 6px;
}


.game-link {
    text-decoration: underline;
    font-weight: 400;
}

.MuiLink-underlineNone {
    text-decoration: none;
}

.settings-link , .settings-link:hover , .settings-link:visited, .settings-link:active {
    color: inherit;
    text-decoration: none;
    }


.upload-btn-wrapper input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
}
.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
}
/* .btn {
    border: 2px solid gray;
    color: gray;
    background-color: white;
    padding: 8px 20px;
    border-radius: 8px;
    font-size: 20px;
    font-weight: bold;
} */

.remove-btn {
    border: unset;
}

.width-3 {
    max-width: 3rem;
    animation-name: hideMaxWidth;
    animation-duration: 0.5s;
    transition: 0.5s;
}
.width-20 {
    max-width: 20rem;
    animation-name: showMaxWidth;
    animation-duration: 0.5s;
    transition: 0.5s;
}
@keyframes hideMaxWidth {
    from {
        max-width: 10rem;
    }
    to {
        max-width: 3rem;
    }
}
@keyframes showMaxWidth {
    from {
        max-width: 3rem;
    }
    to {
        max-width: 10rem;
    }
}

.css-pk3rfy-MuiPaper-root-MuiDrawer-paper{
    overflow-y:clip;    
}
.width-40 {
    min-width: 8rem;
    animation-name: hideWidth;
    animation-duration: 0.5s;
    transition: 0.5s;
}
.width-100 {
    min-width: 16rem;
    animation-name: showWidth;
    animation-duration: 0.5s;
    transition: 0.5s;
}
@keyframes hideWidth {
    from {
        min-width: 16rem;
    }
    to {
        min-width: 8rem;
    }
}
@keyframes showWidth {
    from {
        min-width: 8rem;
    }
    to {
        min-width: 16rem;
    }
}

.opacity-0 {
    opacity: 0;
    animation-name: hideElement;
    animation-duration: 0.5s;
    transition: 0.5s;
}
.opacity-1 {
    opacity: 1;
    animation-name: showElement;
    animation-duration: 0.5s;
    transition: 0.5s;
}
@keyframes hideElement {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}
@keyframes showElement {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.width-none {
    width: 0;
    animation-name: widthNone;
    animation-duration: 0.5s;
    transition: 0.5s;
}
.width-full {
    width: 100%;
    animation-name: widthFull;
    animation-duration: 0.5s;
    transition: 0.5s;
}
@keyframes widthNone {
    from {
        width: 100%;
    }
    to {
        width: 0;
    }
}
@keyframes widthFull {
    from {
        width: 0;
    }
    to {
        width: 100%;
    }
}

.display-none {
    display: none !important;
}

.node-expanded {
    width: 269px;
    animation-name: nodeExpanded;
    animation-duration: 0.5s;
    transition: 0.5s;
}
.node-collapsed {
    width: 85px;
    animation-name: nodeCollapsed;
    animation-duration: 0.5s;
    transition: 0.5s;
}
@keyframes nodeExpanded {
    from {
        width: 85px;
    }
    to {
        width: 250px;
    }
}
@keyframes nodeCollapsed {
    from {
        width: 250px;
    }
    to {
        width: 85px;
    }
}

#canvas {
    width: 500px !important;
}
.menu-size {
    left: 520px !important;
    width: 50% !important;
}

.image-on-leaderboard {
    margin-right: 10px;
    font-weight: bold;

}

.name-on-leaderboard {
    font-weight: 400;
    font-size: 0.875rem;
}
.rank-on-leaderboard {
    font-weight: 500;
    font-size: 0.875rem;
}

.badge-on-leaderboard {
    margin-right: 3%;
    width: 1.625rem;
    heigth: 1.625rem;
    margin-top: 2%;
    /* display:flex;
    justify-content:center;
    flex-direction:row; */
}

.coin-on-leaderboard {
    margin-right: 5%;
}
.sub-title-video-card {
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.188rem;
}
.earn-coins-video-card {
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.188rem;
}

.video-coin-leaderboard {
    font-weight: 400;
}

.swiper-slide {
    /* margin-right:0px !important; */
    /* display: flex;
    justify-content:center; */
}

/* .swiper-button{
    background: unset !important;
} */

.video-tag {
    border-radius: 0.875rem;
    height:26.3rem;
    width:100%;
    
}


.leaderboardImg {
    width: 1.875rem;
    height: 1.875rem;
}

.leaderboardBadgesDialog {
    border-radius: 0.25rem;
}

.pending-item {
    padding: 0.1rem;
    background-color: #ED6C02;
    color: #F8FBFF;
    border-radius: 3rem;
    /* font-size:0.75rem; why is it not working*/
    width: 3.625rem;
}
.done-item {
    padding: 0.1rem;
    background-color: #2E7D32;
    color: #F8FBFF;
    border-radius: 3rem;
    width: 3.625rem;
}

.tasklist-progressbar{
    display:flex;
    flex-direction:row;
    gap:.5rem;
    height:1rem;
}
.linkedin-select-template{
    display:flex;
    flex-direction:row;
    gap:.5rem;  
    align-items:center;
}


/* padding:0.3, backgroundColor: '#ED6C02' , color:'#F8FBFF', borderRadius:'3rem', fontSize:'0.75rem', width:'64px' */

.newsupdate-avatar {
    height: 2.5rem;
}

.event-card-style{
    margin-bottom:1rem;
}

.tasklist-drawer{
    position: relative; 
    min-width: 25em;
    height: 100vh;
}

.videos-dashboard{
    border-radius:1rem;
    width: 100%;
    height: 14rem;}
    
#orgChart ::-webkit-scrollbar {
        display: none !important;
        width: 0px;
        background-color: transparent;
    }

/* .logoSize{
    display:flex;
} */

.dialog-profile{
    padding:2rem !important;
    background-color:#ffffff;
}

.help-center-link{
    color:#1976D2;
    text-decoration-color:#1976D2;
    text-decoration-line: underline;
}

.profile-upload{
        padding-bottom: 4rem;
        margin-top: 1rem; 
        border: 2px dotted lightgrey;
        border-radius: 12px;
        background-color: rgba(201, 201, 201, 0.1);  
}:hover { 
    background-color: "#DBEAFE";
} 



/* textarea, input { outline: none; } */


@media (max-width:599px){
    .drawer-on-mobile{
        display:none;
    }
}

.dashboardStyle{
    width: 100%;
    text-Align: end;
}
#profile-icon{
    background-color:unset !important;
}

#edit-icon:hover #profile-icon{
    border:2px solid blue;
    /* background-color:black; */
}

.settings-list:hover .settings-icon{
    color:black;
    transition: .5s ease;
}


.redirect-icon{
    opacity: 0;
}
.settings-list:hover .redirect-icon{
    opacity: 100;
    transition: .5s ease;
}

.download-icon{
    opacity: 0;
    transition: .5s ease;
}
.policy-list:hover .download-icon{
    transition: .5s ease;
    opacity: 100%;
}

.banner-profile{
    width:100%;
    height:20vh;
}

.overlay-icon-banner{
    position: absolute;
    top: 0;
    bottom: 1.156rem;
    left: 0;
    right: 0;
    opacity: 0;
    transition: .5s ease;
    background-color: rgba(0, 0, 0, 0.5);
}


.multiline-field:hover .overlay-copy-icon{
    opacity: 100;
}

.overlay-copy-icon{
    display:flex;
    flex-direction:row;
    position: absolute;
    top: 5rem;
    left: 28rem;
    right: 8rem;
    opacity: 0;
    transition: .5s ease;
}

.tasklist-coin{
    border-radius:20px;
    width:1.375rem;
    /* height:2.75rem; */
}

.Grid-container{
    margin-top:0px !important;
    margin-left:0px !important;
    column-gap: 1rem;
    width:unset !important;
}

.overlay-lockicon-banner{
    position: absolute;
    height:20%;
    top: 23%;
    bottom: 1.156rem;
    left: 0;
    right: 0;
}

.lock-icon{
    height:3rem;
    width:3rem;
    color:white;
    background-color:grey;
    border-radius:10rem;
    padding:.5rem;
}

.play-icon{
    height:2.5rem;
    width:2.5rem;
}

.card-action:hover .overlay-icon-banner{
    opacity: 100;
}


.disable-link-styles:link {
    text-decoration: none;
    color:black;
}

.disable-link-styles:visited {
    text-decoration: none;
    color:black;
}

.disable-link-styles:hover {
    text-decoration: none;
    color:black;
}

.disable-link-styles:active {
    text-decoration: none;
    color:black;
}

.modal-style{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1162px;
    height: 307px;
    padding: 4;
}
.modal-style2{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 800px;
    padding: 4;
}



.main-profile{
    position:absolute;
    z-index:1;
    top:28%;
    left:4%;
    display: flex;
}


.top-part{
    position:relative;
    height:35vh;
}

.text-stack{
    position: relative;
    height:40%;
    /* display:inline-flex; */
    flex-direction:column; 
}


.profile-text{
    display:flex;
    align-content:flex-end;
}

.dialog-profile-main{
    padding:2rem !important;
    background-color:#ffffff;
}

.text-stack-grid-1{
    padding-left:14rem;
}

.bottom-part{
    margin-top:2rem;
}

.coin-image-profile{
    height:3.125rem;
    width:100%;
}

.emergency-icon-profile{
    color:grey;
}

/* .masonry-grid-item{
    width:50%;
    display:flex;
} */

.css-1betqn-MuiAccordionSummary-content{
    margin:0 !important;
}


input{
    outline: none;
}

.linkedin-drawer-banner{
    object-fit:fill;
    width:8rem;
}


.emp-details-coin-img{
    height:2.1rem;
    width:2.1rem;
    scale:1.5;
}
/* ..css-46bh2p-MuiCardContent-root{
    padding:0rem !important;
} */

/* .masonry-grid{
    column-count:2;
    column-width:500px;
} */
.widthMarginCustom{
width: 3.68rem;
height: 3.06rem;
margin-right: 11px;
}

.widthMarginPPTCustom{
    width: 3.68rem;
    height: 2.50rem;
    margin-right: 11px;
    }

.custom-class {
    position: sticky;
    top: 65px;
    z-index:1200;
  }



@media (max-width:599px){
    .dashboardStyle{
        width: 100%;
        text-Align: start;
    }
    /* .masonry-grid-item{
        width:100%;
        display:block;
    } */
}

@media (max-width:899px){
    .text-stack-grid-1{
    padding-left:0rem;
    padding-top:5rem;
    }
    /* .masonry-grid-item{
        width:100%;
        display:block;
    } */
}

/* 
@media (max-width:899px){
    .profile-badge{
        height:40%;
        width:40%;
        scale:0.6;
    }
} */

/* Default drawer width */
/* .drawer {
    width: 35rem;
  }
  
  @media (max-width: 768px) {
    .drawer {
      width: 25rem;
    }
  }
  
  @media (max-width: 480px) {
    .drawer {
      width: 100%;
      max-width: 20rem;
    }
  } */
  /* .search {
    position: absolute;
    left: 20px;
    right: 20px;
  }
  .wrap {
    height: 480px;
    overflow: auto;
    position: relative;
  }
  
.fix-search .search {
    position: fixed;
    top: 10px;
  } */

 #sticky {
    /* display: block; */
    height: 70px;
}
#sticky.fixed {
    display: block;
    position: fixed;
    top: 62px;
    /* width: 90%; */
    height: 57px;
    background: white;
    z-index: 2;
}

@media screen and (max-width: 480px) {
    #sticky.fixed{
        top: 56px;
    }
  }


#hide {
    position: fixed;
    top:50vh;
    left:40vh;
  }
  
  #height {
    position: fixed;
     top : 1px;
     left:1px;
  }
  
  #container {
    min-height:2px;
  }
 .MuiAutocomplete-popper{
    z-index: 99999; /* Increase the z-index */
  }

  .MuiLinearProgress-root .MuiLinearProgress-bar{
    background-color: #7da308 !important;
  }

  .MuiTooltip-popper.MuiTooltip-popperInteractive{
    z-index: 10000;
  }
/*--------------------------------------------------myconnect Tingg-------------------------------------------------*/
  .overflow-text {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Limit to 3 lines */
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow-wrap: break-word;
  }
  .overflow-text-Announcement {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* Limit to 3 lines */
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow-wrap: break-word;
  }
 
  /* ------------------- collage css start------------- */


.carousel-container{
    width: 550px;
    height: 400px;
}
.top-image-container{
  height: 230px;
}
.bottom-image-container{
  height: 170px;
}
.top-image, .image-source{
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  cursor: pointer;
  height: 100%;
}
.top-image-container, .bottom-image-container{
  display: flex;
  gap: 3px;
  margin: 3px;
  flex-wrap: wrap;
  gap: 10px;
  list-style: none;
  margin-top: 18px;
}
.image-source img, .top-image img{
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  object-fit: cover;
  width: 100%;
  height: 100%;
  vertical-align: middle;
}
.backdrop-container{
  position: absolute;
    width: 100%;
    height: 100%;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
}
.backdrop{
    background-color: #000;
    opacity: 0.4;
    width: 100%;
    height: 100%;
    z-index: 1;
    position: absolute;
    border-radius: 1rem;

}
.backdrop-container .span-classes{
  color: white;
  font-weight: 600;
  font-size: 35px;
  z-index: 1;
}
.auto-width-height, .auto-width-height img{
  height: 100%;
  width: 100%;
}
.single-post{
    height: 328px; 
    width: 680px;
    border-radius: 1rem;
}
.icon-size{
    height: 21px;
}
.icon-size-1{
    height: 18px;
}

.height-1{
    height:40rem;
    overflow: auto;
}
.text-center {
    text-align: center !important;
}
.img-fluid {
    max-width: 100%;
    border-radius: 1rem;
}
.post-radius{
    border-radius: 1rem;
}
.MuiAvatar-root{
    background-color: none !important;
}


.img-style{
    height: 10rem;
    width: 10rem;
}
.img-close-style:hover .btn-delete {
    background-color: white;
  }

  .close-icon-container {
    opacity: 0;
    transition: opacity 0.2s;
  }
  .img-close-style:hover .close-icon-container {
    opacity: 1;
  }

  .responsive-img {
    max-width: 100%;
    height: auto;    
  }

  .custom-textfield {
    border: 1px solid #ccc;
    transition: border-color 0.2s ease;

    &:focus {
        outline: none;
        border-color: #007BFF;
    }
}

.swiper-wrapper{
    align-items: center !important;
  }
